import Footer from "../components/Footer";
import { MailchimpForm1 } from "../components/mailchimpforms/HomeFormOne";
import Navbar from "../components/Navbar";
import React, { useState } from "react";
import ErrorAlert from "../components/alerts/errorAlert";
import LoadingAlert from "../components/alerts/loadingAlert";
import SucessAlert from "../components/alerts/sucessAlert";
import FadeInSection from "../components/FadeInSection";
const Status = {
  Open: "Open",
  Closed: "Closed",
  Results: "Results",
};

const badgeColorMap = {
  Open: "bg-emerald-100 text-emerald-800",
  Closed: "bg-rose-100 text-rose-800",
  Results: "bg-indigo-100 text-indigo-800"
};

const posts = [
  {
    title: "Future Ready: Fab Lab Results",
    href: "/articles/04-03-2025",
    description:
    "Check out the results of our latest Fab Lab VR Career night!  Over 30 participants came for a night of fun exciting learning.",
    date: "April 3, 2025",
    datetime: "2025-4-03",
    status: Status.Results
  },
  {
    title: "STEMEPA Hackathon: Innovator's Challenge",
    href: "/articles/03-26-2025",
    description:
    "A beginner-friendly but high-stakes coding competition for teens aged 13–18, pushing logic, speed, and creativity. No AI. No shortcuts. Just real programming. May 2025 at Northampton Library.",
    date: "March 26, 2025",
    datetime: "2025-3-26",
    status: Status.Open

  },
  {
    title: "Future Ready: STEAM Career Exploration Night x Fab Lab",
    href: "/articles/03-22-2025",
    description:
    "In partnership with the Bucks County Fab Lab and IU, STEMEPA is proud to host our newest event!  Experience and familiarize yourself with future with interesting future careers in the world of Virtual Reality.",
    date: "March 22, 2025",
    datetime: "2025-3-22",
    status: Status.Closed

  },
  {
    title: "STEMEPA Presidential Volunteer Service Awards",
    href: "/articles/01-14-2025",
    description:
    "STEMEPA has been officialy acknowledged by the United States Government as a Certifying Organization for the President's Volunteer Service Award, the highest national volunteer recognition in the country!",
    date: "January 14, 2025",
    datetime: "2025-1-14",
    status: Status.Open

  },
  {
    title: "STEM Detectives: Mystery Mission at Arena STEM [Finished]",
    href: "/articles/10-27-2024",
    description:
    "A mysterious crime has occurred on site at Arena STEM, with a group of evil science criminals leaving behind a cryptic path of hints!  Put on your trench coats and grab your magnifying glasses.  For more information, click below.",
    date: "October 10, 2024",
    datetime: "2024-10-27",
    status: Status.Results

  },
  {
    title: "LEGO Racer STEM Night at Arena STEM [Finished]",
    href: "/articles/05-29-2024",
    description:
    "On July 25 at 950 Industrial Blvd, STEMEPA is hosting LEGO Racer STEM Night along with Arena STEM for all local families in the Bucks County area!  For more information, click below.",
    date: "May 29, 2024",
    datetime: "2024-05-29",
    status: Status.Results

  },
  {
    title: "The Pennsylvania Science and Innovation Competition (PSIC)",
    href: "/articles/06-20-2023",
    description:
    "The Pennsylvania Science and Innovation Competition (PSIC) is an annual regional science research competition organized by STEM Enrichment PA. For more information click below",
    date: "Jun 20, 2023",
    datetime: "2023-06-20",
    status: Status.Closed

  },
  {
    title: "Website Development Workshop",
    href: "/articles/01-21-2023",
    description:
    "STEMEPA will host an online workshop on the fundamentals of web development! A computer is necassary and we would like only ages 12+ to particpate. For more information click below",
    date: "Jan 21, 2023",
    datetime: "2023-01-21",
    status: Status.Closed

  },

  {
    title: "New Education Resources",
    href: "/articles/11-23-2023",
    description:
    "STEM Enrichment of Pennsylvania is proud to present our latest endevour to offer new education resources to anyone free of charge.",
    date: "November 23, 2023",
    datetime: "2023-11-23",
    status: Status.Closed

  },
  
];

export default function Events() {
  const [errorState, setErrorState] = useState(false);
  const [sucessState, setSucessState] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  return (
    <div className="bg-background">
      <Navbar />
      <div className=" px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
        <div className="relative mx-auto max-w-lg lg:max-w-7xl">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Past Events.
            </h2>
            <div>{errorState ? <ErrorAlert /> : null}</div>
            <div>{sucessState ? <SucessAlert /> : null}</div>
            <div>{loadingState ? <LoadingAlert /> : null}</div>
            <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:items-center lg:gap-5">
              <p className="text-xl text-gray-500">
                Get weekly updates on our events and how to join them by joining
                our newletter to the right!
              </p>
              <div className="">
                {/* MailChimp Form */}
                <MailchimpForm1
                  setSucessState={setSucessState}
                  setErrorState={setErrorState}
                  setLoadingState={setLoadingState}
                />
              </div>
            </div>
          </div>

          <div class="relative flex py-5 items-center">
            <div class="flex-grow border-t-2 border-gray-200"></div>
          </div>
          
          <div className="mt-6 grid gap-16 pt-10 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
            <FadeInSection>
            {posts.map((post) => (
              <div key={post.title} className="">
                <p className="text-sm text-gray-500">
                  <time dateTime={post.datetime}>{post.date}</time>
                </p>
                <a href={post.href} className="mt-2 block">
                  <p className="text-xl font-semibold text-gray-900">
                    {post.title}
                  </p>
                  <span className={`inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium ${badgeColorMap[post.status]}`}>
                    {post.status}
                  </span>


                  <p className="mt-3 text-base text-gray-500">
                    {post.description}
                  </p>
                </a>
                <div className="mt-3">
                  <a
                    href={post.href}
                    className="text-base font-semibold text-sm font-medium text-rose-500 hover:text-rose-700"
                  >
                    Read full story &rarr;
                  </a>
                </div>
                <br></br>
              </div>
              
            ))}
            </FadeInSection>
            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
