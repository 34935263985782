export default function LogoCloud() {
  const partners = [
    { name: "Bucks County Library", src: "./assets/Partners/Bucks County Library.jpg" },
    { name: "Stemist Hacks", src: "./assets/Partners/stemist.png" },
    { name: "Devpost", src: "./assets/Partners/devpost.svg" },
    { name: "Echo 3D", src: "./assets/Partners/echo3D.png" },
    { name: "Sticker Giant", src: "./assets/Partners/sticker giant.png" },
    { name: "FAB LAB", src: "./assets/Partners/FAB LAB.png" },
    { name: "Council Rock School District", src: "./assets/Partners/CR.jpeg" }
  ];

  return (
    <div className="-mt-24 py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-3xl font-bold tracking-tight text-rose-600 mb-12">
          Our Partners
        </h2>
        <div className="grid grid-cols-2 gap-10 md:grid-cols-3 lg:grid-cols-4">
          {partners.map((partner, idx) => (
            <div key={idx} className="flex flex-col items-center">
              <div className="bg-white shadow-lg rounded-lg p-4 w-full flex items-center justify-center h-28">
                <img
                  className="h-12 object-contain max-w-[80%]"
                  src={partner.src}
                  alt={partner.name}
                />
              </div>
              <div className="mt-2 text-center text-sm font-medium text-gray-700">
                {partner.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}