// import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Events from "./pages/Events";
import NotFound from "./pages/NotFound";
import About from "./pages/About";
import Partners from "./pages/Partners";
import Resources from "./pages/Resources";

// Articles
import { JanTwentyFirst2023, JuneTwentith2023, MayTwentyNinth2024, NovemberThird2023, OctoberTwentySeventh2024, JanuaryFourteenth2025, MarchTwentySecond2025, MarchTwentySixth2025, HackathonDetails,AprilThird2025 } from "./components/articles";
import WebDevelopmentArticle from "./components/articles/Website";


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* -------------------------------------------------------------------------- */
          /*                                    Pages                                   */
          /* -------------------------------------------------------------------------- */}

          <Route exact path="/" element={<Home />} />
          <Route exact path="ContactUs" element={<ContactUs />} />
          <Route exact path="Events" element={<Events />} />
          <Route exact path="404" element={<NotFound />} />
          <Route exact path="*" element={<NotFound/>} />
          <Route exact path="/About" element={<About/>} />
          <Route exact path="/Partners" element={<Partners/>} />
          <Route exact path="/Resources" element={<Resources/>} />
          
          {/* -------------------------------------------------------------------------- */
          /*                                   Articles                                  */
          /* -------------------------------------------------------------------------- */}
          <Route exact path="/articles/01-21-2023" element={<JanTwentyFirst2023/>} />
          <Route exact path="/articles/05-29-2024" element={<MayTwentyNinth2024/>} />
          <Route exact path="/articles/06-20-2023" element={<JuneTwentith2023/>} />
          <Route exact path="/articles/11-23-2023" element={<NovemberThird2023/>} />
          <Route exact path="/articles/10-27-2024" element={<OctoberTwentySeventh2024/>} />
          <Route exact path="/articles/01-14-2025" element={<JanuaryFourteenth2025/>} />
          <Route exact path="/articles/03-22-2025" element={<MarchTwentySecond2025/>} />
          <Route exact path="/articles/03-26-2025" element={<MarchTwentySixth2025/>} />
          <Route exact path="/articles/webdev" element={<WebDevelopmentArticle/>} />
          <Route exact path="/articles/hackathon-details" element={<HackathonDetails/>} />
          <Route exact path="/articles/04-03-2025" element={<AprilThird2025/>} />



        </Routes>
      </Router>
    </div>
  );
}

export default App;
