import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const HomeFormTwo = ({
  status,
  message,
  onValidated,
  setErrorState,
  setSucessState,
  setLoadingState,
}) => {


  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
      });
  };

  return (
    <form
      className=""
      onSubmit={(e) => handleSubmit(e)}
    >
        {status === "sending" &&
          (setLoadingState(true), setSucessState(false), setErrorState(false))}
        {status === "error" &&
          (setErrorState(true), setLoadingState(false), setSucessState(false))}
        {status === "success" &&
          (setSucessState(true), setLoadingState(false), setErrorState(false))}

        {status !== "success" ? (
          <div className="mt-12 sm:mx-auto sm:flex sm:max-w-lg">
            <div className="min-w-0 flex-1">
              <input
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                value={email}
                placeholder="Enter your email"
                isRequired
                className="bg-white block w-full rounded-md border border-transparent px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-xs focus:border-transparent focus:outline-hidden focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500"
              />
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-3">
              <button
                type="submit"
                label="subscribe"
                className="block w-full rounded-md border border-transparent bg-gray-900 px-5 py-3 text-base font-medium text-white shadow-sm hover:bg-black focus:outline-hidden focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500 sm:px-10"
              >
                Add Me
              </button>
            </div>
          </div>
        ) : null}
    </form>
  );
};



const MailchimpForm2 = (props) => {
  const url = `https://gmail.us21.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&amp;id=${process.env.REACT_APP_MAILCHIMP_ID}&amp;f_id=0021c7e1f0`;

  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <HomeFormTwo
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
            setSucessState={props.setSucessState}
            setErrorState={props.setErrorState}
            setLoadingState={props.setLoadingState}
          />
        )}
      />
    </div>
  );
};
export { HomeFormTwo, MailchimpForm2 };
