export default function ArticleBodyEnhancedEventRecap() {
  return (
    <div className="relative overflow-hidden py-12 bg-gradient-to-b from-white to-gray-50">
      {/* Background decorative elements */}
      <div className="absolute inset-0 z-0 overflow-hidden">
        <svg className="absolute top-0 left-0 w-full opacity-5" viewBox="0 0 800 800">
          <defs>
            <pattern id="grid-pattern" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect width="4" height="4" fill="currentColor" className="text-rose-600" />
            </pattern>
          </defs>
          <rect width="800" height="800" fill="url(#grid-pattern)" />
        </svg>
        
        {/* Decorative code symbols */}
        <svg className="absolute right-0 top-20 h-64 w-64 text-gray-200 opacity-20" viewBox="0 0 100 100">
          <text x="10" y="30" className="text-xl" fill="currentColor">{"{"}</text>
          <text x="20" y="50" className="text-xl" fill="currentColor">{"if (mystery) {"}</text>
          <text x="30" y="70" className="text-xl" fill="currentColor">{"solvePuzzle();"}</text>
          <text x="20" y="90" className="text-xl" fill="currentColor">{"}"}</text>
          <text x="10" y="110" className="text-xl" fill="currentColor">{"}"}</text>
        </svg>
        
        {/* Floating gears illustration */}
        <svg className="absolute left-10 bottom-20 h-40 w-40 text-rose-600 opacity-10" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="40" fill="none" stroke="currentColor" strokeWidth="8" />
          <circle cx="50" cy="50" r="20" fill="none" stroke="currentColor" strokeWidth="6" />
          <path d="M50 10 L50 20 M50 80 L50 90 M10 50 L20 50 M80 50 L90 50 M22 22 L30 30 M70 70 L78 78 M22 78 L30 70 M78 22 L70 30" stroke="currentColor" strokeWidth="4" />
        </svg>
      </div>
      
      {/* Main content container */}
      <div className="relative z-10 px-6 lg:px-8 max-w-6xl mx-auto">
        {/* Header section with animated border */}
        <div className="relative mx-auto max-w-prose text-lg mb-16">
          <div className="absolute -inset-1 bg-gradient-to-r from-rose-600 to-indigo-600 rounded-lg blur opacity-25 animate-pulse"></div>
          <div className="relative bg-white rounded-lg shadow-xl p-6">
            <h1>
              <div className="flex items-center justify-center mb-4">
                <svg className="h-8 w-8 text-rose-600 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z" />
                  <circle cx="12" cy="10" r="3" />
                </svg>
                <span className="text-lg font-semibold text-rose-600 tracking-wider">
                  CASE FILE: NOV-22-2024 | CONFIDENTIAL: MISSION ACCOMPLISHED
                </span>
              </div>
              <span className="mt-4 block text-center text-5xl font-bold leading-tight tracking-tight text-gray-900 sm:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-gray-900 to-gray-700">
                STEM DETECTIVES
              </span>
              <span className="block text-center text-3xl font-bold leading-tight mt-2 text-rose-600">
                Mystery Solved at Arena STEM!
              </span>
              <div className="flex items-center justify-center mt-4 space-x-2">
                <svg className="h-5 w-5 text-amber-500" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118l-2.8-2.034c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <span className="text-lg text-gray-700 font-medium">22 Young Detectives • Mission Success • Scientific Secrets Recovered</span>
                <svg className="h-5 w-5 text-amber-500" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118l-2.8-2.034c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              </div>
            </h1>
            
            {/* Organization logo banner */}
            <div className="flex justify-center items-center mt-6 space-x-8">
              <div className="relative h-20">
                <div className="absolute inset-0 bg-rose-100 rounded-lg opacity-20"></div>
                <img className="relative h-full object-contain p-2" src="../assets/logo.png" alt="STEM EPA Logo" />
              </div>
              <svg className="h-8 w-8 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M12 5v14M5 12h14" />
              </svg>
              <div className="relative h-20">
                <div className="absolute inset-0 bg-rose-100 rounded-lg opacity-20"></div>
                <img className="relative h-full object-contain p-2" src="../assets/1-removebg-preview.png" alt="Arena STEM Logo" />
              </div>
            </div>
          </div>
        </div>

        {/* Main story section */}
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Left sidebar with event stats */}
          <div className="lg:col-span-3">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden sticky top-6">
              <div className="bg-rose-600 px-4 py-3">
                <h3 className="text-white font-bold text-lg">Mission Statistics</h3>
              </div>
              <div className="p-4 space-y-4">
                <div className="flex items-center space-x-2">
                  <svg className="h-5 w-5 text-rose-600" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                  </svg>
                  <div>
                    <div className="text-sm text-gray-500">Detectives</div>
                    <div className="text-lg font-bold">22</div>
                  </div>
                </div>
                
                <div className="flex items-center space-x-2">
                  <svg className="h-5 w-5 text-rose-600" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                  </svg>
                  <div>
                    <div className="text-sm text-gray-500">Date</div>
                    <div className="text-lg font-bold">Nov 22, 2024</div>
                  </div>
                </div>
                
                <div className="flex items-center space-x-2">
                  <svg className="h-5 w-5 text-rose-600" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                  </svg>
                  <div>
                    <div className="text-sm text-gray-500">Location</div>
                    <div className="text-lg font-bold">Arena STEM</div>
                  </div>
                </div>
                
                <div className="flex items-center space-x-2">
                  <svg className="h-5 w-5 text-rose-600" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                  </svg>
                  <div>
                    <div className="text-sm text-gray-500">Funds Raised</div>
                    <div className="text-lg font-bold">$200</div>
                  </div>
                </div>
                
                <div className="flex items-center space-x-2">
                  <svg className="h-5 w-5 text-rose-600" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                  </svg>
                  <div>
                    <div className="text-sm text-gray-500">Age Range</div>
                    <div className="text-lg font-bold">K-4th Grade</div>
                  </div>
                </div>

                <div className="pt-4 border-t border-gray-200">
                  <a href="https://www.arenastempa.com/" className="flex items-center justify-center space-x-2 text-rose-600 hover:text-rose-800 transition-colors font-medium">
                    <span>Visit Arena STEM</span>
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          
          {/* Main content area */}
          <div className="lg:col-span-9 space-y-8">
            {/* Mission recap card */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="p-6">
                <div className="flex items-center mb-4">
                  <svg className="h-6 w-6 text-rose-600 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <h2 className="text-2xl font-bold text-gray-900">Mission Recap</h2>
                </div>
                
                <p className="text-lg text-gray-700 leading-relaxed mb-4">
                  <span className="font-bold text-rose-600 text-xl">Case Closed!</span> STEM Detectives:  Mystery Mission was an amazing success as 22 young minds successfully recovered stolen scientific secrets from a band of dastardly science criminals! 
                </p>
                
                <div className="relative overflow-hidden py-12 bg-gradient-to-b from-white to-gray-50">
      {/* Background decorative elements */}
      <div className="absolute inset-0 z-0 overflow-hidden">
        <svg className="absolute top-0 left-0 w-full opacity-5" viewBox="0 0 800 800">
          <defs>
            <pattern id="grid-pattern" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect width="4" height="4" fill="currentColor" className="text-rose-600" />
            </pattern>
          </defs>
          <rect width="800" height="800" fill="url(#grid-pattern)" />
        </svg>
      </div>
      
      {/* Main content container */}
      <div className="relative z-10 px-6 lg:px-8 max-w-6xl mx-auto">
        <div className="relative mx-auto max-w-prose text-lg mb-16">
          <div className="relative bg-white rounded-lg shadow-xl p-6">
            <h1 className="text-center text-5xl font-bold leading-tight tracking-tight text-gray-900">
              STEM DETECTIVES: Mystery Solved at Arena STEM!
            </h1>
            <p className="text-lg text-gray-700 leading-relaxed mt-4">
              Our young detectives took on the challenge at Arena STEM and successfully cracked the case! With immersive STEM activities, teamwork, and critical thinking, participants uncovered hidden scientific secrets and learned hands-on problem-solving skills in a thrilling environment.
            </p>
          </div>
        </div>

        {/* Event details */}
        <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
          <h2 className="text-2xl font-bold text-gray-900">Event Highlights</h2>
          <ul className="list-disc list-inside text-lg text-gray-700 mt-4">
            <li>22 young detectives participated</li>
            <li>Engaging STEM puzzles and challenges</li>
            <li>Real-world applications of STEM concepts</li>
            <li>Collaboration and teamwork</li>
            <li>Cyphers and Decoding</li>
            <li>Car Engineering</li>
            <li>LEGO Race Assembly</li>


          </ul>
        </div>

        {/* Call to action */}
        <div className="text-center mt-8">
          <p className="text-lg text-gray-700">Want to be part of our next event? Stay tuned for more STEM adventures!</p>
        </div>
      </div>
    </div>
    </div>

    </div>

    </div>
    </div>
    </div>
    </div>

  );
}
