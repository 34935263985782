export default function Header(props) {
  return (
    <div>
      {/* Header */}
      <div>
        <div className={"py-24 lg:py-32" + props.className}>
          <div className="relative z-10 mx-auto max-w-7xl pl-4 pr-8 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl">
              {props.heading}
            </h1>
            <p className="mt-6 max-w-4xl text-xl text-warm-gray-500">
                {props.text}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
