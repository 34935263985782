import React, { useState } from 'react';
import { 
  Trophy, Download, Clock, Code, Users, Pizza, 
  Laptop, Award, Shield, MapPin 
} from 'lucide-react';

export default function STEMEPAHackathonDetails() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const prizeTiers = [
    {
      rank: '🥇 1st Place',
      prize: 'High-Performance Wireless Earbuds + Custom Mechanical Keyboard',
      color: 'bg-gradient-to-br from-yellow-100 to-yellow-200 text-yellow-900 shadow-lg',
      icon: <Trophy className="w-16 h-16 text-yellow-600 animate-pulse" />
    },
    {
      rank: '🥈 2nd Place',
      prize: 'Exclusive STEMepa Swag Pack + Personalized Achievement Certificate',
      color: 'bg-gradient-to-br from-gray-100 to-gray-200 text-gray-900 shadow-md',
      icon: <Award className="w-16 h-16 text-gray-600 transform hover:rotate-12 transition" />
    },
    {
      rank: '🥉 3rd Place',
      prize: 'Limited Edition STEMepa Swag Pack + Recognition Certificate',
      color: 'bg-gradient-to-br from-orange-100 to-orange-200 text-orange-900 shadow-md',
      icon: <Award className="w-16 h-16 text-orange-600 hover:scale-110 transition" />
    }
  ];

  const faqQuestions = [
    {
      question: "What programming languages can I use?",
      answer: "Python and Java are our supported languages. Choose your weapon of code!",
      icon: <Code className="w-6 h-6 text-rose-500" />
    },
    {
      question: "Can I use the internet during the event?",
      answer: "Nope! Internet is strictly for code submission. Copy-paste or AI assistance? Instant disqualification!",
      icon: <Shield className="w-6 h-6 text-rose-500" />
    },
    {
      question: "How are submissions judged?",
      answer: "Precision is key! Submissions are graded by matching the official answer key.",
      icon: <Trophy className="w-6 h-6 text-rose-500" />
    },
    {
      question: "What should I bring?",
      answer: "Your battle gear: Laptop 💻, charger 🔌, and signed permission form. Pro tip: Set up a collaborative coding platform beforehand!",
      icon: <Laptop className="w-6 h-6 text-rose-500" />
    },
    {
      question: "How many members per team?",
      answer: "Assemble your coding squad! Up to 3 members per team.",
      icon: <Users className="w-6 h-6 text-rose-500" />
    },
    {
      question: "Is food provided?",
      answer: "Fuel for thought incoming! 🍕 Pizza and water will keep your energy levels high.",
      icon: <Pizza className="w-6 h-6 text-rose-500" />
    }
  ];

  const problemAllocationData = [
    { difficulty: 'Easy', noviceTime: '10–12 mins', advancedTime: '8–10 mins' },
    { difficulty: 'Hard', noviceTime: '20–25 mins', advancedTime: '18–20 mins' },
    { difficulty: 'Bonus', noviceTime: '5–10 mins', advancedTime: '5–10 mins' }
  ];

  // Simulated download function (would typically be connected to actual file hosting)
  const handleDownloadPermissionForm = () => {
    // In a real application, this would trigger a file download
    const link = document.createElement('a');
    link.href = '/path/to/hackathon-permission-form.pdf';
    link.download = 'STEMepa-Hackathon-Permission-Form.pdf';
    link.click();
  };

  return (
    <div className="bg-gradient-to-br from-rose-50 to-white min-h-screen">
      {/* Event Header with Time and Location */}
      <header className="bg-rose-600 text-white py-8 text-center">
        <div className="container mx-auto px-4">
          <h1 className="text-5xl font-extrabold mb-4">STEMepa Hackathon</h1>
          <div className="flex justify-center items-center space-x-4 text-xl">
            <Clock className="w-8 h-8" />
            <span>5:00 PM - 9:00 PM</span>
            <MapPin className="w-8 h-8 ml-4" />
            <span>Northampton Public Library</span>
          </div>
        </div>
      </header>

      {/* Rest of the previous sections remain the same, with minor styling enhancements */}
      
      {/* =================== REGISTRATION CTA =================== */}
      <section className="bg-rose-600 text-white py-16 text-center">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-6">Ready to Compete?</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Only 20 teams will be selected. Don't miss your chance to showcase your coding skills!
          </p>
          <div className="flex justify-center space-x-4">
            <button 
              onClick={handleDownloadPermissionForm}
              className="flex items-center bg-white text-rose-600 px-8 py-3 rounded-full font-bold hover:bg-gray-100 transition space-x-2"
            >
              <Download className="w-6 h-6" />
              <span>Download Permission Form</span>
            </button>
          </div>
        </div>
      </section>

      {/* =================== PRIZES SECTION =================== */}
      <section className="container mx-auto px-4 py-16">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-rose-600 mb-4">Competition Prizes</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Exciting rewards await the top-performing teams! Limited spots means higher stakes.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-6">
          {prizeTiers.map((tier, index) => (
            <div 
              key={index} 
              className={`${tier.color} p-6 rounded-xl shadow-lg transform hover:scale-105 transition duration-300`}
            >
              <div className="flex items-center mb-4">
                {tier.icon}
                <h3 className="ml-4 text-2xl font-bold">{tier.rank}</h3>
              </div>
              <p className="text-lg">{tier.prize}</p>
            </div>
          ))}
        </div>
      </section>
      {/* =================== PROBLEM ALLOCATION TABLE =================== */}
      <section className="container mx-auto px-4 py-16">
        <h2 className="text-4xl font-bold text-rose-600 text-center mb-12">Problem Allocation</h2>
        <div className="overflow-x-auto">
          <table className="w-full bg-white shadow-lg rounded-lg">
            <thead className="bg-rose-100">
              <tr>
                <th className="p-4 text-left">Difficulty</th>
                <th className="p-4 text-center">Novice Time</th>
                <th className="p-4 text-center">Advanced Time</th>
              </tr>
            </thead>
            <tbody>
              {problemAllocationData.map((row, index) => (
                <tr key={index} className="border-b">
                  <td className="p-4 font-bold">{row.difficulty}</td>
                  <td className="p-4 text-center">{row.noviceTime}</td>
                  <td className="p-4 text-center">{row.advancedTime}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>

      {/* =================== FAQ SECTION =================== */}
      <section className="container mx-auto px-4 py-16">
        <h2 className="text-4xl font-bold text-rose-600 text-center mb-12">Frequently Asked Questions</h2>
        <div className="max-w-2xl mx-auto space-y-4">
          {faqQuestions.map((faq, index) => (
            <div 
              key={index} 
              className="bg-white border rounded-lg overflow-hidden"
            >
              <button 
                onClick={() => setActiveAccordion(activeAccordion === index ? null : index)}
                className="w-full text-left p-4 flex justify-between items-center hover:bg-gray-50"
              >
                <span className="font-semibold">{faq.question}</span>
                <span>{activeAccordion === index ? '−' : '+'}</span>
              </button>
              {activeAccordion === index && (
                <div className="p-4 bg-gray-50 text-gray-700">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
    </div>
    
  );
}