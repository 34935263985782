import React, { useState } from "react";

import { MailchimpForm2 } from "./mailchimpforms/HomeFormTwo";

import ErrorAlert from "./alerts/errorAlert";
import LoadingAlert from "./alerts/loadingAlert";
import SucessAlert from "./alerts/sucessAlert";
import FadeInSection from "./FadeInSection";

export default function CTA() {
  const [errorState, setErrorState] = useState(false);
  const [sucessState, setSucessState] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  return (
    <FadeInSection>
 <div>
      {/* CTA section */}
      <div className="relative mt-24 sm:mt-32 sm:py-16">
        <div aria-hidden="true" className="hidden sm:block">
          <div className="absolute inset-y-0 left-0 w-1/2 rounded-r-3xl bg-gray-50" />
          <svg
            className="absolute top-8 left-1/2 -ml-3"
            width={404}
            height={392}
            fill="none"
            viewBox="0 0 404 392"
          >
            <defs>
              <pattern
                id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={392}
              fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
            />
          </svg>
        </div>
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="relative overflow-hidden rounded-2xl bg-rose-500 px-6 py-10 shadow-xl sm:px-12 sm:py-20">
            <div
              aria-hidden="true"
              className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
            >
              <svg
                className="absolute inset-0 h-full w-full"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 1463 360"
              >
                <path
                  className="text-rose-400/40"
                  fill="currentColor"
                  d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                />
                <path
                  className="text-rose-600/40"
                  fill="currentColor"
                  d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                />
              </svg>
            </div>
            <div className="relative">
              <div className="sm:text-center">
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl"  id="newsletter">
                  Join Our Newsletter
                </h2>
                <p className="mx-auto mt-6 max-w-2xl text-lg text-rose-100">
                  To be updated on the latest news and events join our
                  newsletter below!
                </p>
              </div>
              <MailchimpForm2
                setSucessState={setSucessState}
                setErrorState={setErrorState}
                setLoadingState={setLoadingState}
              />
            </div>
          </div>
        </div>
      </div>
      <div>{errorState ? <ErrorAlert /> : null}</div>
      <div>{sucessState ? <SucessAlert /> : null}</div>
      <div>{loadingState ? <LoadingAlert /> : null}</div>
    </div>
    </FadeInSection>
   
  );
}
