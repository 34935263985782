import { Fragment } from "react";
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Dropdown from "./dropdown";

const navigation = [
  { name: "Home", href: "/" },
  { name: "Events", href: "/Events" },
  { name: "About", href: "/About" },
  { name: "Contact Us", href: "/ContactUS" },
  { name: "Partners", href: "/Partners" },
  { name: "Resources", href: "/Resources" },
];

export default function Navbar() {
  return (
    <div>
      <Popover as="header" className="relative">
        <div className="">
          <nav
            className="relative mx-auto flex max-w-7xl items-center justify-between px-6 pt-6 xl:px-8"
            aria-label="Global"
          >
            <div className="flex flex-1 items-center">
              <div className="flex w-full items-center justify-between lg:w-auto">
                <a href="/">
                  <span className="sr-only">STEM EC Logo</span>
                  <img
                    className="h-12 w-auto sm:h-24"
                    src="./assets/logo.png"
                    alt=""
                  />
                </a>
                <div className="-mr-2 flex items-center lg:hidden">
                  <PopoverButton className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-warm-gray-50 p-2 text-warm-gray-400 hover:bg-warm-gray-100 focus:outline-hidden focus:ring-2 focus:ring-rose-500">
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div className="hidden space-x-10 lg:ml-10 lg:flex">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-xl font-medium text-warm-gray-500 hover:text-rose-600"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
            <Dropdown className="hidden lg:inline-block"/>
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <PopoverPanel
            focus
            className="absolute inset-x-0 top-0 z-30 origin-top transform p-2 transition lg:hidden"
          >
            <div className="overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/5">
              <div className="flex items-center justify-between px-5 pt-4">
                <div>
                  <img className="h-8 w-auto" src="./assets/logo.png" alt="" />
                </div>
                <div className="-mr-2">
                  <PopoverButton className="inline-flex items-center justify-center rounded-md bg-white p-2 text-warm-gray-400 hover:bg-rose-100 focus:outline-hidden focus:ring-2 focus:ring-inset focus:ring-rose-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="space-y-1 px-2">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-warm-gray-900 hover:bg-warm-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                  <a
                      key="STEMEPA"
                      href={"https://nj.stemepa.com"}
                      className="block rounded-md px-3 py-2 text-base font-medium text-warm-gray-900 hover:bg-warm-gray-50"
                    >
                      {"Switch to STEM New Jersey"}
                    </a>
                </div>
              </div>
            </div>
          </PopoverPanel>
        </Transition>
      </Popover>
    </div>
  );
}
