import Footer from "./Footer";
import Navbar from "./Navbar";
import ArticleBodyJanTwentyFirst2023 from "./articles/01-21-23";
import ArticleBodyJuneTwentith2023 from "./articles/06-20-23";
import ArticleBodyNovemberThird2023 from "./articles/11-23-23";
import ArticleBodyMayTwentyNinth2024 from "./articles/05-29-2024";
import ArticleBodyOctoberTwentySeventh2024 from "./articles/10-27-24";
import ArticleBodyJanuaryFourteenth2025 from "./articles/01-14-25";
import ArticleBodyMarchTwentySecond2025 from "./articles/03-22-25.jsx"
import ArticleBodyMarchTwentySixth2025 from "./articles/03-26-25";
import STEMEPAHackathonDetails from "./articles/hackathon-details.jsx";
import ArticleBodyAprilThird2025 from "./articles/04-03-25.jsx";

function AprilThird2025(){
    return (
        <div className="bg-background">
        <Navbar/>
        <ArticleBodyAprilThird2025/>
        <Footer/>
        </div>
    );
}
function HackathonDetails(){
    return (
        <div className="bg-background">
        <Navbar/>
        <STEMEPAHackathonDetails/>
        <Footer/>
        </div>
    );
}
function MarchTwentySixth2025(){
    return (
        <div className="bg-background">
        <Navbar/>
        <ArticleBodyMarchTwentySixth2025/>
        <Footer/>
        </div>
    );
}
function MarchTwentySecond2025(){
    return (
        <div className="bg-background">
        <Navbar/>
        <ArticleBodyMarchTwentySecond2025/>
        <Footer/>
        </div>
    );
}
function JanTwentyFirst2023(){
    return (
        <div className="bg-background">
        <Navbar/>
        <ArticleBodyJanTwentyFirst2023/>
        <Footer/>
        </div>
    );
}

function JuneTwentith2023(){
    return (
        <div className="bg-background">
        <Navbar/>
        <ArticleBodyJuneTwentith2023/>
        <Footer/>
        </div>
    )
}

function NovemberThird2023(){
    return (
        <div className="bg-background">
        <Navbar/>
        <ArticleBodyNovemberThird2023/>
        <Footer/>
        </div>
    )
}


function MayTwentyNinth2024(){
    return (
        <div className="bg-background">
        <Navbar/>
        <ArticleBodyMayTwentyNinth2024/>
        <Footer/>
        </div>
    )
}

function OctoberTwentySeventh2024(){
    return (
        <div className="bg-background">
        <Navbar/>
        <ArticleBodyOctoberTwentySeventh2024/>
        <Footer/>
        </div>
    )
}

function JanuaryFourteenth2025(){
    return (
        <div className="bg-background">
        <Navbar/>
        <ArticleBodyJanuaryFourteenth2025/>
        <Footer/>
        </div>
    )
}

export {JanTwentyFirst2023, JuneTwentith2023, NovemberThird2023, MayTwentyNinth2024, OctoberTwentySeventh2024, JanuaryFourteenth2025, MarchTwentySecond2025, MarchTwentySixth2025, HackathonDetails, AprilThird2025}