import React from 'react';

export default function ArticleBodyAprilThird2025() {
  return (
    <div className="relative overflow-hidden py-16 bg-gray-50">
      {/* Hero Section with Improved Overlay */}
      <div className="relative w-full -mt-16">
        {/* Background Image with Enhanced Visual Effects */}
        <div className="absolute inset-0 w-full h-full">
          <img 
            className="w-full h-full object-cover filter blur-sm brightness-50" 
            src="../assets/VRshi.jpg" 
            alt="VR Career Exploration" 
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/60 to-black/30"></div>
        </div>

        {/* Hero Content with Improved Typography and Spacing */}
        <div className="relative px-6 lg:px-8 pt-28 pb-36">
          <div className="mx-auto max-w-prose text-lg">
            <div className="animate-fade-in">
              <h1>
                <span className="block text-center text-lg font-semibold text-rose-400 mb-2 tracking-wide uppercase">
                  Middle School Grades 6-9 • Registration Open
                </span>
                <span className="mt-6 block text-center text-6xl font-extrabold leading-tight tracking-wide text-white sm:text-5xl drop-shadow-lg">
                  STEMEPA × Fab Lab
                </span>
                <span className="mt-3 block text-center text-4xl font-bold leading-tight tracking-wide text-white sm:text-4xl drop-shadow-lg">
                  Future Ready: STEAM Career Exploration Night
                </span>
                <span className="mt-4 block text-center text-xl font-medium leading-8 tracking-tight text-rose-400 sm:text-2xl">
                  Free entry for all students • March 21st, 2025
                </span>
              </h1>
              
              <p className="mt-10 text-xl leading-8 text-white text-center mx-auto max-w-3xl">
                Step into the shoes of professionals across diverse fields with <span className="font-semibold text-rose-400">immersive VR technology</span>. In partnership with the local <span className="font-semibold text-rose-400">Fab Lab</span>, we're offering middle school students a chance to <span className="font-semibold text-rose-400">discover potential career paths</span> in a brand new environment using cutting-edge <span className="font-semibold text-rose-400">META Quest 3</span> headsets.
              </p>
              <p className="mt-4 text-xl leading-8 text-white text-center mx-auto max-w-3xl">
                This <span className="font-semibold italic">free nonprofit event</span> provides students with hands-on experience in various professions, helping them envision future possibilities in an exciting, innovative way.
              </p>
              <p className="mt-4 text-xl leading-8 text-white text-center mx-auto max-w-3xl">
                <span className="bg-rose-600 px-3 py-1 rounded-lg font-semibold">Organized and Run by Council Rock Students!</span>
              </p>

              <div className="flex justify-center mt-12">
                <a 
                  href="#registration" 
                  className="animate-pulse inline-block px-10 py-4 bg-rose-600 text-white font-bold rounded-full shadow-xl hover:bg-rose-700 hover:scale-105 transition duration-300 text-lg"
                >
                  Register Now • Limited Spots!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Detail Section - Redesigned with Better Spacing and Visual Hierarchy */}
      <div className="relative px-6 lg:px-8 bg-background rounded-t-3xl -mt-10 shadow-lg">
        <div className="prose prose-lg max-w-4xl mx-auto mt-12 text-gray-500 py-8">
          <div className="text-center mb-16">
            <span className="inline-block px-4 py-1 bg-rose-100 text-rose-700 rounded-full text-sm font-semibold mb-3">Transformative Experience</span>
            <h2 className="font-extrabold text-rose-600 text-4xl mb-6">Experience Overview</h2>
            <div className="w-20 h-1 bg-rose-500 mx-auto mb-8 rounded-full"></div>
            <p className="text-xl leading-8 text-gray-700">
              Immerse yourself in the world of professional careers through virtual reality! At this unique event, students will rotate through different VR stations, each offering a glimpse into a different career field or industry. Our experienced guides will help students navigate the virtual environments and discuss the educational pathways and skills needed for each career.
            </p>
            <p className="text-xl leading-8 text-gray-700 mt-4">
              This event is specifically designed for middle school students in grades 6-9, providing age-appropriate content that sparks curiosity and inspires future educational choices. All activities are supervised by trained staff and educators.
            </p>
            <p className="text-xl leading-8 text-gray-700 mt-4">
              <span className="font-bold text-rose-600">Proudly organized and run by Council Rock students</span> committed to inspiring younger students to explore STEAM careers.
            </p>
          </div>

          {/* Registration Section - Moved Up */}
          <div className="mt-6" id="registration">
            <div className="text-center">
              <span className="inline-block px-4 py-1 bg-rose-100 text-rose-700 rounded-full text-sm font-semibold mb-3">Limited Spots Available</span>
              <h2 className="font-extrabold text-rose-600 text-4xl mb-6">Registration</h2>
              <div className="w-20 h-1 bg-rose-500 mx-auto mb-8 rounded-full"></div>
              <p className="text-xl leading-8 text-gray-700 mb-6 max-w-2xl mx-auto">
                Please complete the form below to register for the VR Career Exploration Night. Each student must be registered individually.
              </p>
              
              <div className="bg-gray-50 p-6 rounded-xl border-2 border-rose-700 mb-8 max-w-2xl mx-auto">
                <h3 className="text-xl font-bold text-gray-800 mb-3">How to Register:</h3>
                <ol className="text-left space-y-2">
                  <li className="flex items-start">
                    <span className="font-bold mr-2">1.</span>
                    <span>Click the "Register Now" button on the embedded webpage below</span>
                  </li>
                  <li className="flex items-start">
                    <span className="font-bold mr-2">2.</span>
                    <span>Select "Create an Account and Register" option</span>
                  </li>
                  <li className="flex items-start">
                    <span className="font-bold mr-2">3.</span>
                    <span>Fill out all required information and questions</span>
                  </li>
                  <li className="flex items-start">
                    <span className="font-bold mr-2">4.</span>
                    <span>Complete the sign-up process by clicking "Submit"</span>
                  </li>
                </ol>
              </div>
            </div>
            
            <div className="bg-white shadow-lg rounded-2xl overflow-hidden border border-gray-200">
              <iframe
                title="Registration form"
                src="https://reg.learningstream.com/reg/event_page.aspx?ek=0094-0030-d91bafafbf694d36a7fdd2f689eaeb9d"
                className="w-full h-[600px] lg:h-[800px]"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
              >
                Loading registration form...
              </iframe>
            </div>
          </div>

          <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-gray-50 p-8 rounded-2xl shadow-md border-l-4 border-rose-500 hover:shadow-lg transition duration-300">
              <h3 className="text-2xl font-bold text-gray-900 mb-6">What You'll Experience</h3>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <span className="text-rose-600 font-bold text-xl mr-3">•</span>
                  <span className="text-lg">Immersive career simulations using <span className="font-semibold">META Quest 3</span> headsets</span>
                </li>
                <li className="flex items-start">
                  <span className="text-rose-600 font-bold text-xl mr-3">•</span>
                  <span className="text-lg">Guided exploration of multiple professional environments</span>
                </li>
                <li className="flex items-start">
                  <span className="text-rose-600 font-bold text-xl mr-3">•</span>
                  <span className="text-lg">Q&A sessions and <span className="font-semibold">In-Person Drone Operation Activities</span></span>
                </li>
                <li className="flex items-start">
                  <span className="text-rose-600 font-bold text-xl mr-3">•</span>
                  <span className="text-lg">Interactive activities that highlight different career skills</span>
                </li>
              </ul>
            </div>
            <div className="bg-gray-50 p-8 rounded-2xl shadow-md border-l-4 border-rose-500 hover:shadow-lg transition duration-300">
              <h3 className="text-2xl font-bold text-gray-900 mb-6">Featured Career Fields</h3>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <span className="text-rose-600 font-bold text-xl mr-3">•</span>
                  <span className="text-lg font-medium">Biotechnology</span>
                </li>
                <li className="flex items-start">
                  <span className="text-rose-600 font-bold text-xl mr-3">•</span>
                  <span className="text-lg font-medium">Coding & Computer Science</span>
                </li>
                <li className="flex items-start">
                  <span className="text-rose-600 font-bold text-xl mr-3">•</span>
                  <span className="text-lg font-medium">Animal & Veterinary Science</span>
                </li>
                <li className="flex items-start">
                  <span className="text-rose-600 font-bold text-xl mr-3">•</span>
                  <span className="text-lg font-medium">Drone Technology & Aviation</span>
                </li>
                <li className="flex items-start">
                  <span className="text-rose-600 font-bold text-xl mr-3">•</span>
                  <span className="text-lg font-medium">Carpentry / Welding & Trades</span>
                </li>
              </ul>
            </div>
          </div>

          {/* Event Details with Cards */}
          <div className="mt-24 text-center">
            <span className="inline-block px-4 py-1 bg-rose-100 text-rose-700 rounded-full text-sm font-semibold mb-3">Event Information</span>
            <h2 className="font-extrabold text-rose-600 text-4xl mb-6">Event Details</h2>
            <div className="w-20 h-1 bg-rose-500 mx-auto mb-8 rounded-full"></div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-10">
              <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition border border-gray-100">
                <div className="bg-rose-500 text-white w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">Who</h3>
                <p className="text-gray-700">Middle school students (grades 6-9)</p>
                <p className="text-gray-700 mt-2"><span className="font-semibold">Organized by:</span> Council Rock Students</p>
              </div>
              
              <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition border border-gray-100">
                <div className="bg-rose-500 text-white w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">When</h3>
                <p className="text-gray-700">March 21st, 2025</p>
                <p className="text-gray-700 font-semibold">6:00PM - 8:00PM</p>
              </div>
              
              <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition border border-gray-100">
                <div className="bg-rose-500 text-white w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">Where</h3>
                <p className="text-gray-700">Fab Lab Center</p>
                <p className="text-gray-700">One Ivybrook Blvd Suite 190</p>
                <p className="text-gray-700">Warminster, PA 18974</p>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
              <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition border border-gray-100">
                <div className="bg-rose-500 text-white w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">Cost</h3>
                <p className="text-gray-700 text-2xl font-bold text-rose-600">FREE</p>
                <p className="text-gray-500">Sponsored by STEMEPA and Fab Lab</p>
              </div>
              
              <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition border border-gray-100">
                <div className="bg-rose-500 text-white w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">Equipment</h3>
                <p className="text-gray-700">All equipment provided</p>
                <p className="text-gray-700 font-semibold">META Quest 3 headsets</p>
                <p className="text-gray-500">Latest VR technology</p>
              </div>
            </div>
          </div>

          {/* Partnership Section */}
          <div className="mt-24 bg-gray-50 p-10 rounded-2xl">
            <div className="text-center">
              <span className="inline-block px-4 py-1 bg-rose-100 text-rose-700 rounded-full text-sm font-semibold mb-3">Brought To You By</span>
              <h2 className="font-extrabold text-rose-600 text-4xl mb-6">STEMEPA × Fab Lab Partnership</h2>
              <div className="w-20 h-1 bg-rose-500 mx-auto mb-8 rounded-full"></div>
            </div>
            
            <div className="flex flex-col md:flex-row items-center gap-8 mt-8">
              <div className="md:w-1/3 flex justify-center">
                <div className="bg-white p-8 rounded-full shadow-lg">
                  <img src="../assets/logo.png" alt="STEMEPA Logo" className="h-24 w-auto" />
                </div>
              </div>
              
              <div className="md:w-2/3">
                <p className="text-xl leading-8 text-gray-700">
                  This event is made possible through our collaboration with the local Fab Lab, a community workspace dedicated to digital fabrication and STEM education. The Fab Lab's expertise in technology education and access to cutting-edge equipment enables us to provide this unique educational experience.
                </p>
                <p className="text-xl leading-8 text-gray-700 mt-4">
                  Together, we're committed to <span className="font-semibold text-rose-600">inspiring the next generation</span> through hands-on, immersive learning experiences that bridge the gap between education and real-world careers.
                </p>
                <p className="text-xl leading-8 text-gray-700 mt-4">
                  <span className="bg-rose-100 text-rose-700 px-3 py-1 rounded-lg font-semibold">Organized and Run By Council Rock Students</span> who are passionate about STEAM education and community involvement.
                </p>
              </div>
            </div>
          </div>

          {/* FAQ Section */}
          <div className="mt-24">
            <div className="text-center">
              <span className="inline-block px-4 py-1 bg-rose-100 text-rose-700 rounded-full text-sm font-semibold mb-3">Got Questions?</span>
              <h2 className="font-extrabold text-rose-600 text-4xl mb-6">Frequently Asked Questions</h2>
              <div className="w-20 h-1 bg-rose-500 mx-auto mb-8 rounded-full"></div>
            </div>
            
            <div className="mt-8 space-y-6">
              <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition border border-gray-200">
                <h3 className="text-xl font-bold text-rose-600 mb-3">Do students need prior VR experience?</h3>
                <p className="text-gray-700">
                  <span className="font-semibold">No prior experience needed!</span> Our team will provide a complete orientation and guide students through using the equipment safely and effectively.
                </p>
              </div>
              
              <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition border border-gray-200">
                <h3 className="text-xl font-bold text-rose-600 mb-3">What should students bring?</h3>
                <p className="text-gray-700">
                  Students only need to bring a signed permission form (provided after registration). All VR equipment and materials will be provided at the event.
                </p>
              </div>
              
              <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition border border-gray-200">
                <h3 className="text-xl font-bold text-rose-600 mb-3">How many career simulations will students experience?</h3>
                <p className="text-gray-700">
                  Students will have the opportunity to explore <span className="font-semibold">4-6 different career simulations</span>, depending on the time spent at each station and group size.
                </p>
              </div>
              
              <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition border border-gray-200">
                <h3 className="text-xl font-bold text-rose-600 mb-3">Are there any health or safety concerns?</h3>
                <p className="text-gray-700">
                  VR is generally safe, but students with certain conditions like epilepsy or severe motion sickness should consult with their healthcare provider before participating. All activities will be supervised by trained staff.
                </p>
              </div>
              
              <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition border border-gray-200">
                <h3 className="text-xl font-bold text-rose-600 mb-3">Do parents/guardians need to stay for the event?</h3>
                <p className="text-gray-700">
                  Parents are welcome but not required to stay. We'll have adequate supervision throughout the event. However, we recommend parents attend the first 15 minutes for orientation.
                </p>
              </div>
            </div>
          </div>

          {/* Join Us Section */}
          <div className="mt-24 text-center bg-rose-50 p-10 rounded-2xl">
            <h2 className="font-extrabold text-rose-600 text-4xl mb-6">Join Us for This Unique Experience!</h2>
            <p className="text-xl leading-8 mt-4 text-gray-700 max-w-2xl mx-auto">
              Don't miss this chance to explore potential career paths through the power of virtual reality. Space is limited, so register today!
            </p>
            <div className="mt-8">
              <a 
                href="#registration" 
                className="inline-block px-10 py-4 bg-rose-600 text-white font-bold rounded-full shadow-xl hover:bg-rose-700 hover:scale-105 transition duration-300 text-lg"
              >
                Secure Your Spot Now!
              </a>
            </div>
          </div>

          {/* Footer */}
          <div className="mt-24 pt-8 border-t border-gray-200">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="mb-4 md:mb-0 flex items-center">
                <img src="../assets/logo.png" alt="STEMEPA Logo" className="h-12" />
                <span className="text-xl font-bold text-gray-700 ml-3">STEMEPA</span>
              </div>
              <div className="text-gray-500 text-center md:text-right">
                <p>In partnership with <span className="text-rose-600 font-semibold">Fab Lab</span></p>
                <p>Proudly organized by <span className="text-rose-600 font-semibold">Council Rock Students</span></p>
                <p className="mt-2">©2025 STEMEPA | Inspiring tomorrow's innovators today</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}