export default function ArticleBodyEventRecap() {
  return (
    <div className="relative overflow-hidden py-16 bg-background">
      <div className="absolute top-0 left-0 w-full h-64 bg-gradient-to-b from-rose-50 to-transparent"></div>
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
        <div
          className="relative mx-auto h-full max-w-prose text-lg"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-rose-100"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-rose-100"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-rose-100"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative mb-4 px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg bg-background rounded-xl shadow-md p-8 border border-rose-100">
          <h1>
            <span className="block text-center text-lg font-semibold text-rose-600">
              Event Recap - July 25th & 26th, 2024
            </span>
            <span className="mt-2 block text-center text-5xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              LEGO Racer STEM Night at Arena Stem
            </span>
            <span className="mt-2 block text-center text-lg leading-8 tracking-tight text-gray-900 sm:text-2xl">
              A Tremendous Success with 64 Participants!
            </span>
          </h1>
          <div className="flex justify-center mt-4">
            <img className="w-1/2 h-auto mx-2 rounded-lg shadow-lg" src="../assets/logo.png" alt="stemepalogo" style={{ width: '100%', height: '100%' }} />
          </div>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            <span className="font-bold">Arena STEM</span> and the 501c3 nonprofit STEMEPA recently hosted an incredible LEGO Racer STEM Night at <span className="underline font-bold">950 Industrial Blvd, Southampton PA</span>. With an impressive turnout of <span className="font-bold text-rose-600">64 participants</span> across two identical sessions, the event proved to be an extraordinary success that combined competitive spirit with educational exploration. Our young engineers built, coded, and raced LEGO cars while developing critical STEM skills in a fun and engaging environment.
          </p>
          <br></br>
          <div className="aspect-w-16 aspect-h-9">
            <span className="mt-2 block text-center text-4xl font-bold leading-8 tracking-tight text-gray-900">
              Event Highlights
            </span>
            <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-rose-50 p-6 rounded-lg shadow-md border border-rose-200 hover:shadow-lg transition-all duration-300">
                <h3 className="text-xl font-bold text-rose-600 mb-2">Thursday, July 25</h3>
                <p className="text-gray-600">
                  Our first evening saw tremendous energy as participants formed teams and began the exciting process of designing, building, and racing their LEGO racers. The atmosphere was electric with friendly competition and collaborative problem-solving as teams navigated the challenging obstacle course.
                </p>
              </div>
              <div className="bg-rose-50 p-6 rounded-lg shadow-md border border-rose-200 hover:shadow-lg transition-all duration-300">
                <h3 className="text-xl font-bold text-rose-600 mb-2">Friday, July 26</h3>
                <p className="text-gray-600">
                  Our second evening replicated the same successful format with equal enthusiasm. New participants experienced the thrill of the racing tournament, with many ingenious designs making their way through our obstacle course. The competitive spirit was matched only by the joy of learning.
                </p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="flex justify-center mt-8">
          <img
            src="../assets/1-removebg-preview.png"
            alt="arenastemlogo"
            className="w-1/2 h-auto mx-2 rounded-lg shadow-lg"
            style={{ width: '60%', height: '60%' }}
          />
        </div>

        <div className="prose prose-lg prose-indigo mx-auto mt-8 text-gray-500 bg-background rounded-xl shadow-md p-8 border border-rose-100">
          <h2 className="font-semibold text-rose-600">Tournament Results</h2>
          <p>
            Both evenings featured exciting tournament brackets as participants navigated a challenging obstacle course that tested their engineering skills and coding abilities. Each session culminated in thrilling final rounds that had everyone on the edge of their seats!
          </p>
          
          <div className="bg-rose-50 p-6 rounded-lg shadow-md my-4 border border-rose-200">
            <h3 className="text-xl font-bold text-gray-900">Outstanding Achievements</h3>
            <ul className="list-disc pl-5 mt-2">
              <li><span className="font-bold">Speed Champions:</span> Several teams developed incredibly fast and precise vehicles that navigated the course with remarkable efficiency</li>
              <li><span className="font-bold">Design Innovators:</span> We saw impressive creativity in vehicle designs, from streamlined racers to robust all-terrain vehicles</li>
              <li><span className="font-bold">Coding Masters:</span> Some teams demonstrated exceptional programming skills, creating sophisticated algorithms for navigation</li>
              <li><span className="font-bold">Problem Solvers:</span> Many participants showed remarkable adaptability, quickly modifying their designs when faced with obstacles</li>
            </ul>
          </div>
          
          <div className="bg-gradient-to-r from-rose-200 to-rose-50 p-6 rounded-lg shadow-md my-6">
            <h3 className="text-xl font-bold text-rose-600 mb-2">Event By The Numbers</h3>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-center">
              <div className="bg-white p-4 rounded-lg shadow">
                <p className="text-3xl font-bold text-rose-600">64</p>
                <p className="text-sm text-gray-600">Total Participants</p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow">
                <p className="text-3xl font-bold text-rose-600">2</p>
                <p className="text-sm text-gray-600">Exciting Evenings</p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow">
                <p className="text-3xl font-bold text-rose-600">16</p>
                <p className="text-sm text-gray-600">Teams per Night</p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow">
                <p className="text-3xl font-bold text-rose-600">100%</p>
                <p className="text-sm text-gray-600">Fun Had</p>
              </div>
            </div>
          </div>
          
          <h2 className="font-semibold text-rose-600 mt-8">What Made It Special</h2>
          <p>
            Using the <a href="https://education.lego.com/en-us/products/lego-education-wedo-2-0-core-set/45300/" className="underline">LEGO® MINDSTORMS® Education EV3 & LEGO® Education WeDo 2.0</a>, participants embraced the challenge of building and coding their own LEGO cars. The hands-on experience brought out creativity and fostered teamwork, while developing crucial STEM skills in a fun and engaging environment.
          </p>
          <p>
            While the event was designed with elementary school students in mind, we were thrilled to see participants of all ages joining in. The support from Council Rock School District and the mentorship provided by Council Rock high school students created a supportive learning environment for everyone.
          </p>
          
          <br></br>
          <div className="flex justify-center">
            <img className="h-auto rounded-lg shadow-lg border border-rose-200" src="../assets/lego_mindstorms.png" alt="lego_mindstorms" style={{ width: '40%', height: '40%' }} />
          </div>
          <br></br>
          
          <h2 className="font-semibold text-rose-600">Participant Experiences</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <div className="bg-rose-50 p-5 rounded-lg shadow border border-rose-200">
              <div className="flex items-center mb-3">
                <div className="w-8 h-8 bg-rose-600 text-white rounded-full flex items-center justify-center mr-2">
                  <span className="font-bold">"</span>
                </div>
                <p className="italic text-gray-700">My son had a great time here! He loves working with LEGOs and seeing the new moving engines really made him curious.  Thank you so much for hosting the event!</p>
              </div>
              <p className="font-semibold text-right text-rose-600">- Parent of a 3rd grader</p>
            </div>
            <div className="bg-rose-50 p-5 rounded-lg shadow border border-rose-200">
              <div className="flex items-center mb-3">
                <div className="w-8 h-8 bg-rose-600 text-white rounded-full flex items-center justify-center mr-2">
                  <span className="font-bold">"</span>
                </div>
                <p className="italic text-gray-700">I loved racing and customizing my car. We learned how to make it turn better when we hit obstacles and we made it look like Sonic.</p>
              </div>
              <p className="font-semibold text-right text-rose-600">- 4th grade participant</p>
            </div>
          </div>
          
          <h2 className="font-semibold text-rose-600 mt-8">Key Activities</h2>
          <div className="space-y-4">
            <div className="flex border-l-4 border-rose-500 pl-4">
              <div>
                <h3 className="font-bold text-gray-900">LEGO Car Building and Coding</h3>
                <p className="text-gray-600">
                  Participants engaged in both engineering and computer science, assembling their cars from physical LEGOs and coding them using the intuitive tablet platform. The creativity on display was remarkable!
                </p>
              </div>
            </div>
            <div className="flex border-l-4 border-rose-500 pl-4">
              <div>
                <h3 className="font-bold text-gray-900">Obstacle Course Challenge</h3>
                <p className="text-gray-600">
                  After building and coding their cars, kids navigated an exciting obstacle course designed to test their creations' agility and functionality. The competition was fierce but friendly, with designs ranging from speedy racers to robust all-terrain vehicles.
                </p>
              </div>
            </div>
            <div className="flex border-l-4 border-rose-500 pl-4">
              <div>
                <h3 className="font-bold text-gray-900">Interactive Learning</h3>
                <p className="text-gray-600">
                  Throughout both evenings, students worked and collaborated in groups based on age/grade, with cooperation and creativity heavily encouraged. We witnessed remarkable growth in teamwork, leadership, and communication skills.
                </p>
              </div>
            </div>
          </div>
          
          <br></br>
          <h2 className="font-semibold text-rose-600">Arena STEM Experience</h2>
          <div className="bg-rose-50 p-6 rounded-lg shadow-md border-t-4 border-rose-500">
            <p className="text-gray-600">      
              Participants had the opportunity to explore different activity zones at Arena STEM, including immersive VR experiences, additional LEGO creations, drone piloting, and designing self-built racing car tracks. The state-of-the-art facilities provided the perfect backdrop for this exciting STEM adventure.
            </p>
          </div>
         
          
          <br></br>
          <h2 className="font-semibold text-rose-600">Event Success</h2>
          <div className="bg-rose-50 p-6 rounded-lg shadow-md">
            <p className="text-gray-700">
              With 64 participants across our two identical evening sessions, the event exceeded our expectations. Both nights maintained the same high level of enthusiasm and engagement from all participants, demonstrating the tremendous appetite for hands-on STEM learning in our community. The decision to run the same format on consecutive evenings allowed us to accommodate more participants while ensuring everyone received individual attention and guidance.
            </p>
            <p className="text-gray-700 mt-3">
              The identical format on both evenings proved to be a winning formula, with each session featuring its own exciting moments and breakthrough achievements. We're already looking forward to planning our next collaborative event!
            </p>
          </div>
          
          <br></br>
          <h2 className="font-semibold text-rose-600">Event Photos</h2>
          <br></br>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="object-scale-down">
              <figure>
                <img
                  className="w-full rounded-lg shadow-lg border border-rose-200"
                  src="../assets/elizabeth_sahana_lmfao.jpg"
                  alt="STEMEPA Members Building LEGO Racecars"
                  style={{ width: '100%', height: 'auto' }} 
                />
                <figcaption className="text-center mt-2 text-gray-600 italic">
                  Elizabeth and Sahana, two STEMEPA members, showcasing their LEGO Racecar builds that inspired many participants!
                </figcaption>
              </figure>
            </div>
            <div className="bg-rose-50 p-6 rounded-lg flex items-center justify-center">
              <div className="text-center">
                <div className="text-5xl text-rose-600 font-bold mb-4">32</div>
                <p className="text-gray-700">Participants each night experienced the thrill of competitive STEM learning</p>
              </div>
            </div>
          </div>

          <br></br>
          <h2 className="font-semibold text-rose-600">Why This Event Matters</h2>
          <div className="p-6 bg-gradient-to-r from-white to-rose-200 rounded-lg shadow-md">
            <p className="text-gray-700">
              Events like our LEGO Racer STEM Night are crucial for developing 21st-century skills in young learners. By combining play with structured learning, we create memorable experiences that spark ongoing interest in STEM fields. The competitive yet collaborative atmosphere encourages children to think critically, problem-solve, and work together—all essential skills for future success.
            </p>
           
          </div>
          <br></br>
          
          
          <h2 className="font-semibold text-rose-600 mt-8">Tournament Structure</h2>
          <div className="bg-rose-50 p-6 rounded-lg shadow-md border border-rose-200">
            <p className="text-gray-700 mb-4">
              Both evenings featured identical tournament structures that kept participants engaged and excited:
            </p>
            <ol className="list-decimal pl-5 space-y-2">
              <li className="text-gray-700"><span className="font-bold text-rose-600">Design Phase:</span> Teams had 45 minutes to build and program their LEGO racers</li>
              <li className="text-gray-700"><span className="font-bold text-rose-600">Testing Phase:</span> 15 minutes for test runs and adjustments</li>
              <li className="text-gray-700"><span className="font-bold text-rose-600">Preliminary Rounds:</span> Each team completed two timed runs through the obstacle course</li>
              <li className="text-gray-700"><span className="font-bold text-rose-600">Bracket Competition:</span> Top performers advanced through an elimination bracket</li>
              <li className="text-gray-700"><span className="font-bold text-rose-600">Final Championship:</span> The two best-performing teams faced off in an exciting final race</li>
            </ol>
          </div>
          
          <h2 className="font-semibold text-rose-600 mt-8">Special Thanks</h2>
          <div className="bg-rose-50 p-6 rounded-lg shadow-md">
            <p className="text-gray-700">
              We extend our heartfelt gratitude to:
            </p>
            <ul className="list-disc pl-5 mt-3 space-y-1">
              <li className="text-gray-700">Council Rock School District for their ongoing support</li>
              <li className="text-gray-700">The dedicated high school student volunteers who provided mentorship</li>
              <li className="text-gray-700">Arena STEM staff for their expertise and state-of-the-art facilities</li>
              <li className="text-gray-700">All the parents who encouraged their children's participation</li>
              <li className="text-gray-700">And most importantly, our 64 enthusiastic participants!</li>
            </ul>
          </div>
          
          <h2 className="font-semibold text-rose-600 mt-8">Stay Connected</h2>
          <p className="text-gray-700">
            Thank you to everyone who participated and made this event such a tremendous success! Follow us on social media and check our website for announcements about future events. We can't wait to see you at our next STEM adventure!
          </p>
          
          <div className="flex justify-center mt-8">
            <a href="https://www.arenastempa.com/" className="px-6 py-3 bg-rose-600 text-white font-bold rounded-lg hover:bg-rose-700 transition-colors shadow-md hover:shadow-lg">
              Visit Arena STEM
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}