import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectCoverflow } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";

export default function About() {
  return (
    <div className="bg-background">
      {/* Navbar */}
      <Navbar />

      {/* Enhanced Header with Dynamic Background Elements */}
      <div className="relative overflow-hidden">
        {/* Creative animated background elements */}
        <div className="absolute -z-10 w-full h-full">
          {/* Multiple animated gradient orbs for more dynamic background */}
          <div className="absolute top-10 left-20 w-64 h-64 bg-rose-100 rounded-full blur-3xl opacity-20 animate-pulse"></div>
          <div className="absolute bottom-20 right-10 w-80 h-80 bg-rose-200 rounded-full blur-3xl opacity-20 animate-pulse delay-700"></div>
          <div className="absolute top-40 right-20 w-48 h-48 bg-blue-100 rounded-full blur-3xl opacity-10 animate-pulse delay-1000"></div>
          <div className="absolute bottom-10 left-20 w-56 h-56 bg-purple-100 rounded-full blur-3xl opacity-15 animate-pulse delay-500"></div>
          
          {/* Subtle geometric patterns */}
          <div className="absolute top-0 left-0 w-full h-full opacity-5">
            <div className="absolute top-20 left-20 w-20 h-20 border-2 border-rose-300 rounded-lg rotate-12"></div>
            <div className="absolute bottom-40 right-40 w-16 h-16 border-2 border-rose-300 rounded-full"></div>
            <div className="absolute top-60 right-60 w-24 h-24 border-2 border-rose-300 rounded-lg -rotate-12"></div>
          </div>
        </div>
        
        <Header
          heading={
            <span className="bg-gradient-to-r from-rose-500 to-rose-700 bg-clip-text text-transparent">
              Inspiring Tomorrow's Innovators
            </span>
          }
          text={
            <div className="space-y-6 max-w-5xl mx-auto backdrop-blur-sm bg-white/30 p-8 rounded-xl shadow-lg">
              <p className="text-xl font-medium text-gray-800 leading-relaxed border-l-4 border-rose-500 pl-4">
              We are a student-led organization dedicated to transforming STEM education across Pennsylvania—starting at the foundation.
              </p>
              <p className="text-gray-700 leading-relaxed">
              At STEM Enrichment of Pennsylvania, we believe that scientific curiosity begins in childhood at elementary-age learners, long before students ever step into a high school lab.
              </p>
              <p className="text-gray-700 leading-relaxed">
              Our mission is not about stacking resumes or checking boxes for college applications—it’s about something far greater: authentic passion and real impact.
              </p>
              <p className="text-gray-700 leading-relaxed">
              Through hands-on workshops and coding challenges, we spark curiosity and problem-solving in young minds. 
              </p>
              <p className="font-medium text-rose-600 text-lg leading-relaxed italic">
              — Benjamin Wang & Krish Patel              </p>
              </div>
          }
        />
      </div>
      {/* Animated Divider */}
      <div className="relative h-16 overflow-hidden">
        <svg className="absolute bottom-0 w-full h-32" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path 
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V120H0V56.44Z" 
            fill="#f9fafb"
          ></path>
        </svg>
      </div>

      {/* Team Showcase Section */}
      <section className="py-16 relative bg-gray-50">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-white opacity-30"></div>
        
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-2 text-center">
            <span className="bg-gradient-to-r from-rose-500 to-rose-700 bg-clip-text text-transparent">
              Meet Our Leadership Team
            </span>
          </h2>
          <p className="text-xl font-medium text-center text-gray-700 mb-8 max-w-2xl mx-auto">
            By Students, For Students — Passionate innovators driving change in STEM education
          </p>

          <Swiper
            modules={[Pagination, Navigation, Autoplay, EffectCoverflow]}
            effect="coverflow"
            grabCursor={true}
            centeredSlides={true}
            initialSlide={1}
            coverflowEffect={{
              rotate: 20,
              stretch: 0,
              depth: 200,
              modifier: 1,
              slideShadows: true,
            }}
            spaceBetween={0}
            slidesPerView="auto"
            navigation
            pagination={{ clickable: true, dynamicBullets: true }}
            autoplay={{ delay: 4000, disableOnInteraction: false }}
            breakpoints={{
              640: { slidesPerView: 1.5, spaceBetween: 20 },
              768: { slidesPerView: 2, spaceBetween: 30 },
              1024: { slidesPerView: 3, spaceBetween: 40 },
            }}
            className="w-full max-w-6xl mx-auto mt-12"
          >
            <SwiperSlide className="py-8">
              <div className="p-8 bg-white rounded-lg shadow-lg max-w-[350px] h-[550px] mx-auto transform transition-all duration-300 hover:shadow-2xl hover:-translate-y-2">
                <div className="relative mb-8">
                  <div className="absolute -inset-1 bg-gradient-to-r from-rose-400 to-rose-600 rounded-full blur opacity-25 animate-pulse"></div>
                  <img
                    className="rounded-full w-[140px] h-[140px] mx-auto object-cover relative border-4 border-white shadow-md"
                    src="/assets/KP.JPG"
                    alt="Krish Patel"
                  />
                </div>
                <h4 className="mb-2 text-xl font-bold text-gray-800">Krish Patel</h4>
                <div className="text-sm uppercase tracking-wider font-bold text-rose-500 mb-4">
                  Co-President & Lead Chapter Director
                </div>
                <p className="text-gray-600 leading-relaxed">
                  Junior at Council Rock South High School combining <span className="font-medium text-rose-600">medical research</span> with community service. Published researcher, certified EMT, and distinguished finalist at the American Academy of Neurology with exceptional skills.
                </p>
              </div>
            </SwiperSlide>
            
            <SwiperSlide className="py-8">
              <div className="p-8 bg-white rounded-lg shadow-lg max-w-[350px] h-[550px] mx-auto transform transition-all duration-300 hover:shadow-2xl hover:-translate-y-2">
                <div className="relative mb-8">
                  <div className="absolute -inset-1 bg-gradient-to-r from-rose-400 to-rose-600 rounded-full blur opacity-25 animate-pulse"></div>
                  <img
                    className="rounded-full w-[140px] h-[140px] mx-auto object-cover relative border-4 border-white shadow-md"
                    src="/assets/benwang.JPG"
                    alt="Benjamin Wang"
                  />
                </div>
                <h4 className="mb-2 text-xl font-bold text-gray-800">Benjamin Wang</h4>
                <div className="text-sm uppercase tracking-wider font-bold text-rose-500 mb-4">
                  Co-President & Technology Director
                </div>
                <p className="text-gray-600 leading-relaxed">
                  Junior at Council Rock South High School with a passion for <span className="font-medium text-rose-600">innovation</span> and <span className="font-medium text-rose-600">technology</span>. PJAS 1st Place winner, current intern at Andrena/DAWN Internet, and recognized as an NCTE First Class Writer.
                </p>
              </div>
            </SwiperSlide>
            {/* Krish Patel */}
            

           {/* Aarav Upadhyay */}
<SwiperSlide className="py-8">
  <div className="p-8 bg-white rounded-lg shadow-lg max-w-[350px] h-[550px] mx-auto transform transition-all duration-300 hover:shadow-2xl hover:-translate-y-2">
    <div className="relative mb-8">
      <div className="absolute -inset-1 bg-gradient-to-r from-rose-400 to-rose-600 rounded-full blur opacity-25 animate-pulse"></div>
      <img
        className="rounded-full w-[140px] h-[140px] mx-auto object-cover relative border-4 border-white shadow-md"
        src="/assets/IMG_1360.jpg" // Replace with your actual image path
        alt="Aarav Upadhyay"
      />
    </div>
    <h4 className="mb-2 text-xl font-bold text-gray-800">Aarav Upadhyay</h4>
    <div className="text-sm uppercase tracking-wider font-bold text-rose-500 mb-4">
     Co-President
    </div>
    <p className="text-gray-600 leading-relaxed">
      Junior at Council Rock High School North with a strong passion for <span className="font-medium text-rose-600">STEM advocacy</span>, <span className="font-medium text-rose-600">community leadership</span>. PJAS 1st Place winner, Wharton High School Data Science Competition finalist, and active <span className="font-medium text-rose-600">Topological Machine Learning researcher</span>.
    </p>
  </div>
</SwiperSlide>

          </Swiper>
        </div>
      </section>

      {/* Animated Divider */}
      <div className="relative h-16 overflow-hidden">
        <svg className="absolute top-0 w-full h-32" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path 
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V56.44Z" 
            fill="#f9fafb"
          ></path>
        </svg>
      </div>

      {/* Mission Statement */}
      <section className="py-16 bg-background">
        <div className="container mx-auto px-4 max-w-4xl">
          <div className="bg-white p-8 rounded-2xl shadow-lg border-l-4 border-rose-500 relative overflow-hidden">
            {/* Decorative elements */}
            <div className="absolute -top-10 -right-10 w-40 h-40 bg-rose-50 rounded-full opacity-30"></div>
            <div className="absolute -bottom-10 -left-10 w-32 h-32 bg-rose-50 rounded-full opacity-30"></div>
            
            <div className="relative z-10">
              <h3 className="text-3xl font-bold mb-6 text-gray-800 flex items-center">
                <span className="mr-2">Our Impact</span>
                <span className="h-1 w-12 bg-rose-500 rounded-full inline-block"></span>
              </h3>
              <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              <p className="text-gray-700 leading-relaxed">
  We’re transforming STEM education in Pennsylvania through 
  <span className="font-semibold"> hands-on learning, mentorship, and real-world experiences</span>. 
  From <span className="font-semibold">LEGO robotics and VR career exploration to Fab Lab workshops</span>, 
  our programs <span className="font-semibold">ignite curiosity and build problem-solving skills from an early age</span>.
</p>
</p>
              <div className="flex flex-wrap gap-4 justify-center">
                <div className="bg-rose-50 px-6 py-3 rounded-full text-rose-600 font-medium text-base flex items-center transition-all duration-300 hover:bg-rose-100 hover:shadow-md">
                  <span className="w-3 h-3 bg-rose-500 rounded-full mr-2"></span>
                  Innovation
                </div>
                <div className="bg-rose-50 px-6 py-3 rounded-full text-rose-600 font-medium text-base flex items-center transition-all duration-300 hover:bg-rose-100 hover:shadow-md">
                  <span className="w-3 h-3 bg-rose-500 rounded-full mr-2"></span>
                  Accessibility
                </div>
                <div className="bg-rose-50 px-6 py-3 rounded-full text-rose-600 font-medium text-base flex items-center transition-all duration-300 hover:bg-rose-100 hover:shadow-md">
                  <span className="w-3 h-3 bg-rose-500 rounded-full mr-2"></span>
                  Mentorship
                </div>
                <div className="bg-rose-50 px-6 py-3 rounded-full text-rose-600 font-medium text-base flex items-center transition-all duration-300 hover:bg-rose-100 hover:shadow-md">
                  <span className="w-3 h-3 bg-rose-500 rounded-full mr-2"></span>
                  Community
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Stats Section - New Addition */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            <span className="bg-gradient-to-r from-rose-500 to-rose-700 bg-clip-text text-transparent">
              Our Growing Impact
            </span>
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
            <div className="bg-white p-6 rounded-xl shadow-md text-center transform transition-all hover:shadow-xl hover:-translate-y-1">
              <div className="text-4xl font-bold text-rose-500 mb-2">5+</div>
              <div className="text-gray-600">Community Events</div>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-md text-center transform transition-all hover:shadow-xl hover:-translate-y-1">
              <div className="text-4xl font-bold text-rose-500 mb-2">100+</div>
              <div className="text-gray-600">Students Impacted</div>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-md text-center transform transition-all hover:shadow-xl hover:-translate-y-1">
              <div className="text-4xl font-bold text-rose-500 mb-2">4</div>
              <div className="text-gray-600">School Partnerships</div>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-md text-center transform transition-all hover:shadow-xl hover:-translate-y-1">
              <div className="text-4xl font-bold text-rose-500 mb-2">100%</div>
              <div className="text-gray-600">Authenticity</div>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section with enhanced styling */}
      <section className="py-16 relative">
        {/* Background Elements */}
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <div className="absolute -top-10 -left-10 w-64 h-64 bg-rose-50 rounded-full opacity-30"></div>
          <div className="absolute -bottom-10 -right-10 w-80 h-80 bg-rose-50 rounded-full opacity-30"></div>
        </div>
        
        <div className="container mx-auto px-4 relative z-10">
          <h2 className="text-3xl font-bold text-center mb-4">
            <span className="bg-gradient-to-r from-rose-500 to-rose-700 bg-clip-text text-transparent">
              Frequently Asked Questions
            </span>
          </h2>
          <p className="text-center text-gray-600 max-w-2xl mx-auto mb-12">
            Everything you need to know about our organization and how to get involved
          </p>
          <FAQ />
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-rose-500 to-rose-700"></div>
        
        {/* Animated background shapes */}
        <div className="absolute inset-0 opacity-10">
          <div className="absolute top-0 left-10 w-40 h-40 border-4 border-white rounded-full"></div>
          <div className="absolute bottom-10 right-20 w-60 h-60 border-4 border-white rounded-full"></div>
          <div className="absolute top-10 right-10 w-20 h-20 border-4 border-white rounded-full"></div>
          <div className="absolute bottom-0 left-20 w-32 h-32 border-4 border-white rounded-full"></div>
        </div>
        
        <div className="container mx-auto px-4 text-center relative z-10">
          <h2 className="text-4xl font-bold mb-4 text-white">Join Our STEM Movement</h2>
          <p className="max-w-2xl mx-auto mb-8 text-white/90 text-lg">
            Whether you're a student eager to explore STEM opportunities, a business interested in partnership, or an educator seeking collaborative projects, we invite you to be part of our growing community.
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            <a href="/events" className="bg-white text-rose-500 px-8 py-4 rounded-lg font-bold hover:bg-gray-100 transition-all shadow-lg hover:shadow-xl hover:-translate-y-1">
              Upcoming Events
            </a>
            <a href="/contact" className="bg-transparent border-2 border-white px-8 py-4 rounded-lg font-bold text-white hover:bg-white hover:text-rose-500 transition-all shadow-lg hover:shadow-xl hover:-translate-y-1">
              Get Involved
            </a>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
}
