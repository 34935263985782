import React, { useState } from 'react';
import { ArrowRight, Pizza, Code, Users, Trophy } from 'lucide-react';

export default function ArticleBodyMarchTwentySixth2025() {
  const [showGoogleForm, setShowGoogleForm] = useState(false);

  return (
    <div className="relative overflow-hidden bg-gray-50">
      {/* =================== HERO SECTION =================== */}
      <div className="relative w-full">
        {/* Layered Background Effects */}
        <div className="absolute inset-0 overflow-hidden">
        <img 
            className="w-full h-full object-cover filter blur-xs brightness-50" 
            src="/assets/HACKATHON.png" 
            alt="Coding Hackathon" 
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/60 to-black/30"></div>
        {/* Background effects */}
          <div className="absolute -top-10 -left-20 w-96 h-96 bg-rose-200/30 rounded-full blur-3xl animate-pulse"></div>
          <div className="absolute -bottom-10 -right-20 w-96 h-96 bg-rose-200/30 rounded-full blur-3xl animate-pulse"></div>
          <div className="absolute inset-0 bg-gradient-to-br from-white/50 to-rose-100/20 opacity-75"></div>
        </div>

        {/* Hero content */}
        <div className="relative px-6 lg:px-8 pt-20 pb-32 max-w-6xl mx-auto">
          <div className="grid md:grid-cols-2 items-center gap-12">
            {/* Text Section */}
            <div className="space-y-6 z-10 text-white">
              <div className="inline-block px-4 py-2 bg-rose-600 text-rose-100 backdrop-blur-md rounded-full text-sm font-semibold tracking-wide border border-white/20 shadow">
                <Trophy className="inline-block mr-2 w-5 h-5" /> Teens 13–18 • Innovation Awaits
              </div>
                
              <h1 className="text-5xl font-extrabold leading-tight">
                STEMEPA Hackathon: 
                <span className="inline-block bg-rose-600 px-4 py-2 text-rose-100 whitespace-nowrap backdrop-blur-md rounded-xl tracking-wide border border-white/20 shadow ml-2">
                  Innovate Challenge
                </span>
              </h1>

              <p className="text-lg md:text-xl text-gray-100 leading-relaxed">
                Transform your <span className="font-semibold inline-block bg-rose-500 text-rose-100 rounded-xl px-1">coding potential</span> in a high-energy hackathon designed to challenge, inspire, and <span className="font-semibold inline-block bg-rose-500 rounded-xl text-rose-100 px-1">fuel your passion</span> for programming.
              </p>

              <div className="flex space-x-4">
                <button 
                  onClick={() => setShowGoogleForm(true)}
                  className="flex items-center px-6 py-3 bg-rose-600 text-white rounded-lg hover:bg-rose-700 transition-colors group shadow-lg"
                >
                  Register Now
                  <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </button>

                <a 
                  href="/articles/hackathon-details" 
                  className="flex items-center px-6 py-3 border-2 border-white text-white rounded-lg hover:bg-white/10 transition-colors backdrop-blur-md"
                >
                  Learn More
                </a>
              </div>

              {/* Bonus Teaser */}
              <div className="flex items-center text-sm text-rose-100 mt-4">
                <Pizza className="mr-2 w-5 h-5 text-yellow-400" />
                Free Pizza for All Participants!
              </div>
            </div>

            {/* Interactive Coding SVG Illustration */}
            <div className="hidden md:block relative">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 400" className="w-full max-w-md mx-auto">
                    <defs>
                    <linearGradient id="codeGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                        <stop offset="0%" style={{ stopColor: '#F43F5E', stopOpacity: 1 }} />
                        <stop offset="100%" style={{ stopColor: '#BE123C', stopOpacity: 1 }} />
                    </linearGradient>
                    </defs>

                    {/* Laptop Screen */}
                    <rect x="100" y="80" width="300" height="180" rx="12" ry="12" fill="#1F2937" stroke="url(#codeGradient)" strokeWidth="3" />

                    {/* Code Lines - inside screen */}
                    <rect x="120" y="110" width="260" height="16" rx="4" ry="4" fill="url(#codeGradient)" opacity="0.8">
                    <animate attributeName="x" values="120;130;120" dur="2s" repeatCount="indefinite" />
                    </rect>
                    <rect x="140" y="140" width="220" height="14" rx="4" ry="4" fill="url(#codeGradient)" opacity="0.6">
                    <animate attributeName="x" values="140;150;140" dur="2.5s" repeatCount="indefinite" />
                    </rect>
                    <rect x="120" y="170" width="260" height="14" rx="4" ry="4" fill="url(#codeGradient)" opacity="0.5">
                    <animate attributeName="x" values="120;110;120" dur="2s" repeatCount="indefinite" />
                    </rect>

                    {/* Laptop Base */}
                    <rect x="80" y="260" width="340" height="30" rx="8" ry="8" fill="#111827" stroke="#374151" strokeWidth="1" />
                    <rect x="90" y="265" width="320" height="6" rx="3" ry="3" fill="#374151" />

                    {/* Hinge */}
                    <rect x="230" y="255" width="40" height="10" rx="2" ry="2" fill="url(#codeGradient)" />
                </svg>
            </div>
          </div>
        </div>
      </div>

      {/* =================== GOOGLE FORM MODAL =================== */}
      {showGoogleForm && (
        <div className="fixed inset-0 bg-black/60 z-50 flex items-center justify-center p-4 backdrop-blur-sm">
          <div className="bg-white rounded-2xl shadow-2xl w-full max-w-4xl relative overflow-hidden">
            <div className="absolute top-0 left-0 right-0 h-2 bg-gradient-to-r from-rose-500 to-pink-500"></div>
            <button 
              onClick={() => setShowGoogleForm(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 z-10"
            >
              ✕
            </button>
            <div className="p-8">
              <h2 className="text-3xl font-bold text-rose-600 mb-4">Join the Hackathon!</h2>
              <p className="text-gray-600 mb-6">Complete the form below to secure your spot.</p>
              <iframe title="suckmyballs" src="https://docs.google.com/forms/d/e/1FAIpQLSflD9OcimpDXcDTnRw9t_haPVrNCgoO5qIdAupkzUSLH5uz9Q/viewform?embedded=true" width="640" height="425" frameborder="0" marginheight="0" marginwidth="0" className="rounded-xl border-2 border-rose-100">Loading…</iframe>
            </div>
          </div>
        </div>
      )}


      {/* =================== SUPPORTIVE ENVIRONMENT PROMO =================== */}
      <div className="relative bg-gradient-to-br from-rose-900 to-rose-700 text-white py-16 px-6 overflow-hidden">
  {/* Background Decorative Elements */}
  <div className="absolute inset-0 opacity-10">
    <div className="absolute -top-10 -left-10 w-72 h-72 bg-white rounded-full blur-3xl"></div>
    <div className="absolute -bottom-10 -right-10 w-72 h-72 bg-white rounded-full blur-3xl"></div>
  </div>

  <div className="relative z-10 max-w-4xl mx-auto text-center space-y-8">
    <div className="inline-flex items-center space-x-3 bg-white/10 px-4 py-2 rounded-full border border-white/20">
      <span className="text-sm font-medium">Community-Driven Learning</span>
    </div>

    <h2 className="text-4xl font-extrabold leading-tight">
      More Than Just a <span className="text-rose-300">Coding Competition</span>
    </h2>

    <div className="grid md:grid-cols-3 gap-6">
      <div className="bg-white/10 p-6 rounded-xl border border-white/20 hover:bg-white/20 transition">
        <Code className="w-12 h-12 text-rose-300 mx-auto mb-4" />
        <h3 className="font-bold text-xl mb-2">Challenge Yourself</h3>
        <p className="text-sm opacity-80">
          Push beyond your comfort zone with real-world coding challenges that test your problem-solving skills.
        </p>
      </div>

      <div className="bg-white/10 p-6 rounded-xl border border-white/20 hover:bg-white/20 transition">
        <Users className="w-12 h-12 text-rose-300 mx-auto mb-4" />
        <h3 className="font-bold text-xl mb-2">Collaborate & Connect</h3>
        <p className="text-sm opacity-80">
          Build lasting friendships with like-minded teens while working together to solve complex coding problems.
        </p>
      </div>

      <div className="bg-white/10 p-6 rounded-xl border border-white/20 hover:bg-white/20 transition">
        <Trophy className="w-12 h-12 text-rose-300 mx-auto mb-4" />
        <h3 className="font-bold text-xl mb-2">Learn from Experts</h3>
        <p className="text-sm opacity-80">
          Gain insights from experienced mentors who are passionate about nurturing the next generation of innovators.
        </p>
      </div>
    </div>

    <div className="flex flex-col items-center space-y-4">
      <p className="text-lg max-w-2xl mx-auto">
        A <span className="font-bold italic text-rose-300">nonprofit initiative</span> designed to transform young programmers into tomorrow's tech leaders.
      </p>

              <a href="/articles/hackathon-details" 
                className="flex items-center px-8 py-4 bg-white text-rose-700 font-bold rounded-full hover:bg-rose-100 transition duration-300 shadow-xl group"
              >
        See More Details!
        <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />                
        </a>
      

      <div className="text-sm flex items-center space-x-2 bg-white/10 px-4 py-2 rounded-full">
        <Users className="w-4 h-4 text-rose-300" />
        <span>Organized by Council Rock Students</span>
      </div>
    </div>
  </div>
</div>
      {/* =================== EVENT DETAILS SECTION =================== */}
      <div className="relative px-6 lg:px-8 bg-background rounded-t-3xl -mt-10 shadow-lg">
        <div className="max-w-4xl mx-auto mt-12 py-8">
          {/* Overview */}
          <div className="text-center mb-16">
            <span className="inline-block px-4 py-1 bg-rose-100 text-rose-700 rounded-full text-md font-semibold mb-3">
              Coding Challenge
            </span>
            <h2 className="font-extrabold text-rose-600 text-4xl mb-6">Event Overview</h2>
            <div className="w-20 h-1 bg-rose-500 mx-auto mb-8 rounded-full"></div>

            <div className="grid md:grid-cols-2 gap-8">
              {/* Left - What You'll Do */}
              <div className="bg-gray-50 p-8 rounded-2xl shadow-md border-l-4 border-rose-500 hover:shadow-lg transition duration-300">
                <h3 className="text-2xl font-bold text-gray-900 mb-6">What You'll Do</h3>
                <ul className="space-y-4 text-left text-lg">
                  <li className="flex items-start">
                    <span className="text-rose-600 font-bold text-3xl mr-3">•</span> Solve complex coding challenges
                  </li>
                  <li className="flex items-start">
                    <span className="text-rose-600 font-bold text-3xl mr-3">•</span> Work and collaborate in small teams
                  </li>
                  <li className="flex items-start">
                    <span className="text-rose-600 font-bold text-3xl mr-3">•</span> Compete for exciting prizes and awards
                  </li>
                  <li className="flex items-start">
                    <span className="text-rose-600 font-bold text-3xl mr-3">•</span> Learn from experienced upperclassmen and mentors
                  </li>
                </ul>
              </div>

              {/* Right - Key Details */}
              <div className="bg-gray-50 p-8 rounded-2xl shadow-md border-l-4 border-rose-500 hover:shadow-lg transition duration-300">
                <h3 className="text-2xl font-bold text-gray-900 mb-6">Key Details</h3>
                <ul className="space-y-4 text-left text-lg">
                  <li className="flex justify-between"><span>📍 Location:</span><span className="font-semibold">Northampton Library</span></li>
                  <li className="flex justify-between"><span>📆 Date:</span><span className="font-semibold">May (Exact TBD)</span></li>
                  <li className="flex justify-between"><span>⏰ Time:</span><span className="font-semibold">5:00 PM – 9:00 PM</span></li>
                  <li className="flex justify-between"><span>💻 Languages:</span><span className="font-semibold">Python & Java</span></li>
                  <li className="flex justify-between"><span>💲 Cost:</span><span className="font-semibold">Free Sign-Up</span></li>
                </ul>
              </div>
            </div>
          </div>

          {/* =================== RULES SECTION =================== */}
          <div className="mt-16 text-center">
            <span className="inline-block px-4 py-1 bg-rose-100 text-rose-700 rounded-full text-sm font-semibold mb-3">Fair Play Matters</span>
            <h2 className="font-extrabold text-rose-600 text-4xl mb-6">Competition Rules</h2>
            <div className="w-20 h-1 bg-rose-500 mx-auto mb-8 rounded-full"></div>

            <div className="bg-white p-8 rounded-2xl shadow-md border border-gray-200">
              <ul className="space-y-4 text-left max-w-2xl mx-auto">
                <li className="flex items-start"><span className="text-rose-600 font-bold text-xl mr-3">•</span> No internet access during challenges</li>
                <li className="flex items-start"><span className="text-rose-600 font-bold text-xl mr-3">•</span> No AI code generation allowed</li>
                <li className="flex items-start"><span className="text-rose-600 font-bold text-xl mr-3">•</span> First correct solution gets highest score</li>
                <li className="flex items-start"><span className="text-rose-600 font-bold text-xl mr-3">•</span> No partial points awarded</li>
                <li className="flex items-start"><span className="text-rose-600 font-bold text-xl mr-3">•</span> Cheating results in immediate disqualification</li>
              </ul>
            </div>
          </div>

          {/* =================== CLOSING CTA =================== */}
          <div className="mt-16 text-center bg-rose-50 p-10 rounded-2xl">
            <h2 className="font-extrabold text-rose-600 text-4xl mb-6">Ready to Code and Compete?</h2>
            <p className="text-xl leading-8 mt-4 text-gray-700 max-w-2xl mx-auto">
              Limited spots available! Register your team now and be part of this exciting coding challenge.
            </p>
            <div className="mt-8">
                <button 
                  onClick={() => setShowGoogleForm(true)}
                  className="inline-block px-10 py-4 bg-rose-600 text-white font-bold rounded-full shadow-xl hover:bg-rose-700 hover:scale-105 transition duration-300 text-lg"
                  >
                Secure Your Team's Spot
                </button>
            </div>
          </div>
        </div>
      </div>
      </div>

);
}