import FadeInSection from "./FadeInSection";

const actions = [
    {
      title: "Congressional App Challenge",
      href: "https://www.congressionalappchallenge.us/",
      description: "A nationwide competition that encourages middle and high school students to learn coding and computer science by creating their own apps."
    },
    {
      title: "Pennsylvania Junior Academy of Sciences",
      href: "https://www.pjas.net/",
      description: "A statewide STEM competition where students conduct independent science research projects and present their findings to a panel of judges."
    },
    {
      title: "ECybermission",
      href: "https://www.ecybermission.com/",
      description: "A web-based STEM competition for students in grades 6-9 that encourages teamwork to solve real-world community challenges through scientific inquiry."
    },
    {
      title: "You Be the Chemist (YBTC)",
      href: "https://www.competitionsciences.org/competitions/you-be-the-chemist-challenge/",
      description: "A fun, interactive academic competition that engages students in chemistry concepts and real-world applications."
    },
    {
      title: "ISEF",
      href: "https://www.societyforscience.org/isef/", 
      description: "The world's largest pre-college STEM competition, where students showcase their independent research and compete for scholarships and awards."
    },
    {
      title: "Governor's School of NJ",
      href: "https://www.nj.gov/govschool/",
      description: "A summer program offering highly talented high school students a chance to engage in advanced academic and research experiences in STEM and the arts."
    },
    {
      title: "International Chemistry Olympiad",
      href: "https://www.ichosc.org/",
      description: "A prestigious chemistry competition that challenges students to demonstrate their knowledge and problem-solving skills in theoretical and practical chemistry."
    },
    {
      title: "USA Computing Olympiad (USACO)",
      href: "https://usaco.org/",
      description: "A national computer science competition that trains and evaluates students on algorithmic problem-solving and programming skills."
    },
    {
      title: "Future Business Leaders of America",
      href: "https://www.fbla.org/",
      description: "An organization that prepares students for careers in business and leadership through academic competitions, leadership development, and networking opportunities."
    },
    {
      title: "Model UN",
      href: "https://www.gpyce.org/mun-conference",
      description: "An educational simulation where students learn about diplomacy, international relations, and the United Nations by representing countries in debates and negotiations."
    },
  ];
  

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function GridList2() {
  return (
    <div className="-mt-12 divide-y divide-gray-200 overflow-hidden rounded-lg shadow-sm sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0 m-5">
      {actions.map((action, actionIdx) => (
        <FadeInSection>
          <div
            key={action.title}
            className={classNames(
              actionIdx === 0
                ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                : "",
              actionIdx === 1 ? "sm:rounded-tr-lg" : "",
              actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
              actionIdx === actions.length - 1
                ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                : "",
              "relative group p-6 bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
            )}
          >
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                <a href={action.href} className="focus:outline-hidden">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                </a>
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                {action.description}
              </p>
            </div>
            <span
              className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        </FadeInSection>
      ))}
    </div>
  );
}
