import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function directToPA() {
    window.location.href = "https://nj.stemepa.com";
}

export default function Dropdown({className}) {
  return (
    <Menu as="div" className={"relative text-left " + (className || "")}>
      <div>
        <MenuButton className="inline-flex w-full items-center justify-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        <img
                src="/assets/vectors/pa.svg"
                alt="NJ"
                className="inline-block w-10 h-10"
                display="flex"
              />
          <ChevronDownIcon
            aria-hidden="true"
            className="-mr-1 size-5 text-gray-400 mb-1"
          />
        </MenuButton>
      </div>

      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-hidden data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div>
          <MenuItem>
            <button onClick={directToPA} className="group flex w-full items-center gap-2 rounded-md py-1.5 px-3 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-hidden">
            <img
                src="/assets/vectors/nj.svg"
                alt="NJ"
                className="inline-block w-10 h-10 mr-2"
                display="flex"
              />
              <p className="inline-flex py-2 text-sm text-black">STEM New Jersey</p>
            </button>
          </MenuItem>
        
        </div>
      </MenuItems>
    </Menu>
  );
}
