import FadeInSection from "./FadeInSection";

const faqs = [
  {
    id: 1,
    question: "What is STEM?",
    answer:
      "STEM stands for Science Technology Engineering and Mathematics. Find more information at https://www.bestcolleges.com/blog/what-is-stem.",
  },
  {
    id: 2,
    question: "How do I get involved as a student?",
    answer:
      "Join our newsletter and follow our social media for information on our future events and any other opportunities. We also plan to offer leadership opportunities to students, so please stay posted.",
  },
  {
    id: 3,
    question: "What is a partner?",
    answer:
      "A partner is any organization or business which supports our mission and works with our organization to inspire STEM literacy and excitement across Pennsylvania. Visit our partners' page for more information.",
  },
  {
    id: 4,
    question: "How do I become a partner?",
    answer:
      "Don't hesitate to contact us if you want to become a partner, as we are always happy to talk.",
  },
  {
    id: 5,
    question: "Are there any volunteering opportunities for high schoolers?",
    answer:
      "Although we cannot currently offer LINCS or other volunteering hours, we plan to work with our partners to have them available.",
  },
  {
    id: 6,
    question: "Any more questions?",
    answer:
      "Visit our contact us page or email us, and we'll get back to you as soon as possible.",
  },
  // More questions...
];

export default function FAQ() {
  return (
    <div>
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900">
          Frequently asked questions
        </h2>
        <div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-gray-200"></div>
          </div>
        <div className="mt-5">
          <dl className="divide-y divide-gray-200">
            {faqs.map((faq) => (
              <FadeInSection>
                <div
                  key={faq.id}
                  className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8"
                >
                  <dt className="text-base font-medium text-gray-900 md:col-span-5">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 md:col-span-7 md:mt-0">
                    <p className="text-base text-gray-500">{faq.answer}</p>
                  </dd>
                </div>
              </FadeInSection>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
